<script>
  export let style = "";
  let clazz = "";
  export { clazz as class };
</script>

<svg viewBox="0 0 32 32" class={clazz} {style}>
  <path
    d="M31.296 7.68c-.256-.32-.704-.512-1.216-.512-.448 0-.896.192-1.216.512L16
    20.736 3.2 7.68c-.256-.32-.704-.512-1.216-.512-.448
    0-.896.192-1.216.448S.256 8.32.256 8.768c0 .512.192.96.512 1.28L14.72
    24.32c.32.32.704.512 1.152.512.256 0
    .512-.064.768-.192.128-.064.256-.128.384-.128l.128-.064
    14.016-14.336c.768-.64.704-1.728.128-2.432z"
  />
</svg>
